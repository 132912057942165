import { getAccessToken } from '../../../configuration/tokenHandling/selectors';

import { VehiclesHistoryActions, VehiclesHistoryThunkAction, VehiclesHistoryThunkDispatch } from './types';
import { config } from '../../../config';
import { HistoryContentEnum } from './components/types';
import { SortDirection } from '../vehicleCharging/types';
import { buildUrlWithDates } from '../utils/dates';
import ChargingProcess from './models/ChargingProcess';
import { State } from '../../../types';
import { ErrorCodeEnum } from '../common/enums/ErrorCode';
import { getLocalAccessToken } from '../../../api/Api';

export const VEHICLE_CHARGING_PROCESSES_LOADING_STARTED = 'VEHICLE_CHARGING_PROCESSES_LOADING_STARTED';
export const VEHICLE_CHARGING_PROCESSES_LOADING_SUCCESSFUL = 'VEHICLE_CHARGING_PROCESSES_LOADING_SUCCESSFUL';
export const VEHICLE_CHARGING_PROCESSES_LOADING_FAILED = 'VEHICLE_CHARGING_PROCESSES_LOADING_FAILED';
export const HISTORY_ASSETS_SELECTED = 'HISTORY_ASSETS_SELECTED';
export const HISTORY_TABLE_SORT_CHANGED = 'HISTORY_TABLE_SORT_CHANGED';
export const SELECTED_HISTORY_CONTENT_CHANGED = 'SELECTED_HISTORY_CONTENT_CHANGED';

let token = '';

export const fetchVehicleChargingProcesses = (startDate?: any, endDate?: any): VehiclesHistoryThunkAction<void, {}> => {
    return async (dispatch: VehiclesHistoryThunkDispatch, getState: () => State) => {
        const accessToken = getAccessToken(getState());
        dispatch({
            type: VEHICLE_CHARGING_PROCESSES_LOADING_STARTED,
        });
        if (import.meta.env.DEV) {
            token = await getLocalAccessToken();
        }
        const rootEndPoint = `${config.backend.OTA_CHARGING_MONITORING_SERVICE}/assets/charge-cycle-activities?`;
        const endpoint = buildUrlWithDates(rootEndPoint, startDate, endDate);
        const response = await fetch(endpoint, {
            headers: {
                Authorization: `Bearer ${import.meta.env.DEV ? token : accessToken}`,
            },
        });

        if (response.status === 200) {
            const json = await response.json();
            const chargingProcesses = mapChargingProcesses(json);
            dispatch({
                type: VEHICLE_CHARGING_PROCESSES_LOADING_SUCCESSFUL,
                payload: chargingProcesses,
            });
        } else {
            if (response.status === 404) {
                dispatch({
                    type: VEHICLE_CHARGING_PROCESSES_LOADING_FAILED,
                    payload: ErrorCodeEnum.NOT_FOUND,
                });
            } else {
                dispatch({
                    type: VEHICLE_CHARGING_PROCESSES_LOADING_FAILED,
                    payload: ErrorCodeEnum.OTHER,
                });
            }
        }
    };
};

export const historyTableSortChanged = (sortDir: SortDirection, sortBy: string): VehiclesHistoryActions => {
    return {
        type: HISTORY_TABLE_SORT_CHANGED,
        payload: {
            sortDir,
            sortBy,
        },
    };
};

export const selectedHistoryContentChanged = (selection: HistoryContentEnum): VehiclesHistoryActions => {
    return {
        type: SELECTED_HISTORY_CONTENT_CHANGED,
        payload: selection,
    };
};

const mapChargingProcesses = (json: any): ChargingProcess[] => {
    return json.items.map((item: any) => {
        return new ChargingProcess(
            item.asset_id,
            item.average_battery_current_amps,
            item.average_battery_voltage_volts,
            item.duration,
            item.end_time,
            item.energy_added_kwh,
            item.energy_level_kwh_at_start_time,
            item.max_battery_current_amps,
            item.max_battery_voltage_volts,
            item.min_battery_voltage_volts,
            item.remaining_range_km_at_start_time,
            item.start_time,
            item.state_of_charge_percentage_at_start_time,
            item.type,
            item.powertrain_energy_consumption_kwh,
            item.powertrain_energy_consumption_percentage,
            item.aux_energy_consumption_kwh,
            item.aux_energy_consumption_percentage,
            item.ams_energy_consumption_kwh,
            item.ams_energy_consumption_percentage,
            item.average_ambient_temperature_celsius,
            item.minimum_ambient_temperature_celsius,
            item.maximum_ambient_temperature_celsius,
            item.max_battery_temperature_warning_c,
            item.min_battery_temperature_warning_c,
            item.total_energy_consumption_kwh,
            item.driven_distance_km
        );
    });
};
