import { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Moment } from 'moment';
import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../../utils/googleTagManager';
import { downloadHistoryFile, downloadMinimalDatasetFile } from '../../../../../api/history';
import { createFile, FileFormat } from './createFile';
import { State } from '../../../../../types';
import { getAccessToken, getLocale } from '../../../../../configuration';
import { getDateWithTimezoneOffset } from '../../../history/newHistory/queries/useHistory';

interface HistoryDownloadButtonProps {
    startDate?: Moment;
    endDate?: Moment;
}

export const HistoryDownloadButton = ({ startDate, endDate }: HistoryDownloadButtonProps): ReactElement => {
    const [isDownloading, setIsDownloading] = useState(false);
    const intl = useIntl();
    const accessToken = useSelector((state: State) => getAccessToken(state));
    const language = useSelector((state: State) => getLocale(state));

    const downloadFile = (downloadFunction: Function, format: FileFormat, action: TrackingAction) => {
        setIsDownloading(true);
        if (!startDate || !endDate) {
            Notification.error(intl.formatMessage({ id: 'e4c.history.dataDownload.error' }));
            return;
        }

        const startTime = getDateWithTimezoneOffset(startDate.toDate());
        const endTime = getDateWithTimezoneOffset(endDate.toDate());

        return downloadFunction(accessToken, startTime, endTime, format, language)
            .then((response: Response) => {
                createFile(response, startDate.format('DD-MM-YYYY'), endDate.format('DD-MM-YYYY'), format);
                gtmUtil.pushGaEvent(createGoogleAnalyticsEvent(TrackingCategory.HISTORY_TAB, action));
                Notification.success(intl.formatMessage({ id: 'e4c.history.dataDownload.success' }));
            })
            .catch(() => Notification.error(intl.formatMessage({ id: 'e4c.history.dataDownload.error' })))
            .finally(() => setIsDownloading(false));
    };

    const items = [
        {
            value: intl.formatMessage({ id: 'e4c.history.download.button.csv' }),
            onSelect: () => downloadFile(downloadHistoryFile, FileFormat.CSV, TrackingAction.HISTORY_CSV_DOWNLOAD),
        },
        {
            value: intl.formatMessage({ id: 'e4c.history.download.button.xlsx' }),
            onSelect: () => downloadFile(downloadHistoryFile, FileFormat.XLSX, TrackingAction.HISTORY_XLSX_DOWNLOAD),
        },
        {
            value: intl.formatMessage({ id: 'e4c.history.download.button.minimaldataset' }),
            onSelect: () =>
                downloadFile(downloadMinimalDatasetFile, FileFormat.XLSX, TrackingAction.MINIMAL_DATASET_XLSX_DOWNLOAD),
        },
    ];

    if (isDownloading) {
        return <button className="btn btn-default btn-loading btn-icon-only" />;
    }

    return (
        <ButtonDropdown
            data-testid="new-history-download-button"
            title={<span className="rioglyph rioglyph-download" />}
            items={items}
            dropdownClassName="white-space-nowrap"
            iconOnly
        />
    );
};
