import { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import detailComfortTimerCreationPanelModalImage from '../../../../../assets/images/detail-comfort-timer-creation-panel-modal.png';
import detailComfortTimerClimatePanelModalImage from '../../../../../assets/images/detail-comfort-timer-climate-panel-modal.png';
import UserManualPage from '../components/UserManualPage';

const ComfortTimerItem = (): ReactElement => {
    const intl = useIntl();

    return (
        <UserManualPage
            title={<FormattedMessage id="e4c.userManual.details.comfortTimer.title" />}
            dataTestId="comfort-timer-item"
        >
            <p>
                <FormattedMessage id="e4c.userManual.details.comfortTimer.titleDescription" />
            </p>
            <div className="display-flex margin-bottom-20">
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.comfortTimer.creation' })}
                    src={detailComfortTimerCreationPanelModalImage}
                    className="margin-right-25"
                    height="350"
                />
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.comfortTimer.climate' })}
                    src={detailComfortTimerClimatePanelModalImage}
                    height="350"
                />
            </div>
            <div className="counter color-onboarding size-small filled">
                <div>
                    <p className="margin-bottom-0 margin-top-0">
                        <span className="text-bold">
                            <FormattedMessage id="e4c.userManual.details.comfortTimer.addNew" />
                        </span>{' '}
                        <FormattedMessage id="e4c.userManual.details.comfortTimer.addNew.description" />
                    </p>
                </div>
                <div>
                    <p className="margin-bottom-0 margin-top-0">
                        <span className="text-bold">
                            <FormattedMessage id="e4c.userManual.details.comfortTimer.climateControl" />
                        </span>{' '}
                        <FormattedMessage id="e4c.userManual.details.comfortTimer.climateControl.description" />
                    </p>
                </div>
                <div>
                    <p className="margin-bottom-0 margin-top-0">
                        <span className="text-bold">
                            <FormattedMessage id="e4c.userManual.details.comfortTimer.timerLabel" />
                        </span>{' '}
                        <FormattedMessage id="e4c.userManual.details.comfortTimer.timerLabel.description" />
                    </p>
                </div>
            </div>
        </UserManualPage>
    );
};

export default ComfortTimerItem;
