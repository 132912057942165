import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';
import AssetDetails from '../VehicleDetailsInfo/AssetDetailsInfo/AssetDetails';
import { VehicleChargingData } from '../../../models/VehicleChargingData';
import notVanishingNotificationError from './notVanishingNotificationError';
import { useContext, useEffect } from 'react';
import { VehicleDetailsContext } from '../../../../../../providers/contexts';
import VehicleDetailsProvider from '../../../../../../providers/VehicleDetailsProvider';
import ProgrammedConfiguration from '../VehicleDetailsConfiguration/ProgrammedConfiguration/ProgrammedConfiguration';
import VehicleTypeClass from '../../../mappers/vehicleIcons/VehicleTypeClass';
import VehicleModel from '../../../models/enums/VehicleModel';
import ChargingMode from './ChargingMode/ChargingMode';
import { UpcomingFeatureState } from './UpcomingFeatureState';
import UnsavedChangesDialog from './ChargingMode/UnsavedChangesDialog';
import VehiclesDetailsError from '../VehicleDetailsError/VehicleDetailsError';
import { ErrorCodeEnum } from '../../../../common/enums/ErrorCode';
import SaveChargingSettings from './ChargingMode/SaveChargingSettings';
import { useFeatureToggle } from '../../../../common/hooks/useFeatureToggle';
import { FeatureToggles } from '../../../../../../configuration/featureToggle/featureToggles';

interface VehicleDetailsSidebarProps {
    vehicle: VehicleChargingData;
    onCloseSidebar: () => void;
    onToggleConfirmDialog: (show: boolean) => void;
    onToggleUnsavedVehicleChanges: (show: boolean) => void;
}

export const VehicleDetailsSidebar = ({
    vehicle,
    onCloseSidebar,
    onToggleConfirmDialog,
    onToggleUnsavedVehicleChanges,
}: VehicleDetailsSidebarProps) => {
    const { value: isCm4ChargingConfigEnabled } = useFeatureToggle(FeatureToggles.CHARGING_CONFIGURATIONS_FOR_CM4);

    const {
        isReadOnly,
        hasUnsavedChanges,
        sendingChargingConfigFailed,
        setSendingChargingConfigFailed,
        sendingChargingConfigSucceeded,
        setSendingChargingConfigSucceeded,
        changedChargingTab,
        resetContext,
        dialog,
    } = useContext(VehicleDetailsContext);

    const handleCloseSidebar = (): void => {
        if (hasUnsavedChanges || changedChargingTab) {
            onToggleConfirmDialog(true);
        } else {
            onCloseSidebar();
            resetContext();
        }
    };

    // Notification handling for save success/failure
    useEffect(() => {
        if (sendingChargingConfigFailed) {
            notVanishingNotificationError(<FormattedMessage id="e4c.vehicle.details.save.failure" />);
            setSendingChargingConfigFailed(false);
        }

        if (sendingChargingConfigSucceeded) {
            Notification.success(<FormattedMessage id="e4c.vehicle.details.save.success" />, '', 3000);
            setSendingChargingConfigSucceeded(false);
        }
    }, [
        sendingChargingConfigFailed,
        sendingChargingConfigSucceeded,
        setSendingChargingConfigFailed,
        setSendingChargingConfigSucceeded,
    ]);

    // Show pending changes pop up when selecting other vehicle
    useEffect(() => {
        if (hasUnsavedChanges || changedChargingTab) {
            onToggleUnsavedVehicleChanges(true);
        } else {
            onToggleUnsavedVehicleChanges(false);
        }
    }, [hasUnsavedChanges, changedChargingTab, onToggleUnsavedVehicleChanges]);

    const renderChargingDetails = () => {
        const { vehicleModel } = vehicle.assetInfo;
        const { assetId } = vehicle;

        if ([VehicleModel.ELSA, VehicleModel.TRUE].includes(vehicleModel) && !isCm4ChargingConfigEnabled) {
            return <UpcomingFeatureState />;
        }

        if (vehicleModel === VehicleModel.UNKNOWN) {
            return <VehiclesDetailsError errorCode={ErrorCodeEnum.NOT_FOUND} />;
        }

        if (vehicleModel === VehicleModel.ETGE) {
            return <ProgrammedConfiguration typeClass={VehicleTypeClass.VAN} />;
        }

        return <ChargingMode assetId={assetId} />;
    };

    return (
        <Sidebar
            title={<FormattedMessage id="e4c.sidebar.title" />}
            titleClassName="padding-left-5"
            headerClassName="min-height-0"
            closed={false}
            onClose={handleCloseSidebar}
            position="right"
            resizable={false}
            width={615}
            fly
            footer={!isReadOnly && <SaveChargingSettings assetId={vehicle.assetId} />}
        >
            {vehicle && <AssetDetails vehicleData={vehicle} />}
            <hr className="margin-0" />
            {renderChargingDetails()}
            <UnsavedChangesDialog
                showDialog={dialog.isOpen}
                onConfirm={dialog.confirmDialog}
                onCancel={dialog.cancelDialog}
            />
        </Sidebar>
    );
};

const NewVehicleDetailsSidebar = ({
    vehicle,
    onCloseSidebar,
    onToggleConfirmDialog,
    onToggleUnsavedVehicleChanges,
}: VehicleDetailsSidebarProps) => (
    <VehicleDetailsProvider>
        <VehicleDetailsSidebar
            vehicle={vehicle}
            onCloseSidebar={onCloseSidebar}
            onToggleConfirmDialog={onToggleConfirmDialog}
            onToggleUnsavedVehicleChanges={onToggleUnsavedVehicleChanges}
        />
    </VehicleDetailsProvider>
);

export default NewVehicleDetailsSidebar;
