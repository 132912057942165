import { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReleaseNotes from '@rio-cloud/rio-uikit/ReleaseNotes';
import UserManualPage from '../components/UserManualPage';
import { whatsNewData } from './whatsNewData';
import { WhatsNewContent, WhatsNewData } from './types';
import { FeatureTogglesT, useFeatureToggle } from '../../../../hooks/useFeatureToggle';
import { FeatureToggles } from '../../../../../../../configuration/featureToggle/featureToggles';

const WhatsNewItem = (): ReactElement => {
    const intl = useIntl();
    const formattedMessageValues = {
        b: (chunks: any) => <b>{chunks}</b>,
        u: (chunks: any) => <u>{chunks}</u>,
        ul: (chunks: any) => <ul className="margin-left-25">{chunks}</ul>,
        ol: (chunks: any) => <ol className="margin-left-25">{chunks}</ol>,
        li: (chunks: any) => <li>{chunks}</li>,
    };

    const buildContent = (content: WhatsNewContent) => {
        return (
            <div key={content.title.value}>
                <div className={content.title.className ?? 'margin-bottom-10 margin-top-0 text-size-h2'}>
                    <FormattedMessage id={content.title.value} />
                </div>
                {content.items.map((item, index) => (
                    <div key={index} className={index < content.items.length - 1 ? 'margin-bottom-20' : ''}>
                        <div className={item.description.className}>
                            <FormattedMessage id={item.description.value} values={formattedMessageValues} />
                        </div>
                        {item.image && (
                            <img
                                className={
                                    item.image.className ??
                                    'margin-top-20 width-100pct border-style-solid border-width-1 border-color-lighter margin-bottom-10'
                                }
                                src={item.image.value}
                            />
                        )}
                        {item.footer && (
                            <div className={item.footer.className ?? 'text-size-12'}>
                                <FormattedMessage id={item.footer.value} />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        );
    };

    const featureToggles = {
        [FeatureToggles.NEW_HISTORY]: !!useFeatureToggle(FeatureToggles.NEW_HISTORY).value,
        [FeatureToggles.REDESIGN_DOWNLOAD]: !!useFeatureToggle(FeatureToggles.REDESIGN_DOWNLOAD).value,
        [FeatureToggles.REDESIGN_CHARGING_CONTEXT]: !!useFeatureToggle(FeatureToggles.REDESIGN_CHARGING_CONTEXT).value,
    };
    const whatsNewDataFiltered = getWhatsNewDataFiltered(featureToggles);

    const releaseNotesData = whatsNewDataFiltered.reduce((releaseNotes, item): WhatsNewData => {
        return {
            ...releaseNotes,
            [intl.formatMessage({ id: item.version })]: {
                date: item.date,
                content: buildContent(item.content),
            },
        };
    }, {});

    return (
        <UserManualPage title={<FormattedMessage id={'e4c.userManual.whatsNew'} />} dataTestId="whats-new-item">
            <ReleaseNotes releaseNotes={releaseNotesData} data-testid="release-notes" />
        </UserManualPage>
    );
};

export const getWhatsNewDataFiltered = (featureToggles: Partial<Record<FeatureTogglesT, boolean>>) => {
    const disabledToggles = Object.entries(featureToggles)
        .filter(([, isEnabled]) => !isEnabled)
        .map(([toggle]) => toggle as FeatureTogglesT);

    return whatsNewData.filter(({ toggle }) => !toggle || !disabledToggles.includes(toggle));
};

export default WhatsNewItem;
