import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { cloneDeep } from 'lodash';
import { PLACEMENT } from '@rio-cloud/rio-uikit/values/Placement';
import Button from '@rio-cloud/rio-uikit/Button';
import { VehicleDetailsContext } from '../../../../../../../../providers/contexts';
import TooltipRef from '../../../../../../common/components/Tooltip/Tooltip';

const CreateTimerButtons = () => {
    const intl = useIntl();
    const { showNewTimerForm, hasUnsavedChanges, setUnsavedTimers, setHasUnsavedChanges, timers, dialog } =
        useContext(VehicleDetailsContext);

    const handleCreateTimer = (isComfortTimer: boolean) => {
        if (hasUnsavedChanges) {
            dialog.openDialog(handleDialogConfirm);
        } else {
            showNewTimerForm(isComfortTimer);
        }
    };

    const handleDialogConfirm = () => {
        setUnsavedTimers(cloneDeep(timers));
        setHasUnsavedChanges(false);
        showNewTimerForm(false);
    };

    return (
        <div className="margin-top-20 display-flex">
            <Button
                bsStyle={Button.PRIMARY}
                iconName="rioglyph-time"
                className="width-100pct justify-content-start btn-multiline"
                data-testid="add-new-timer"
                onClick={() => handleCreateTimer(false)}
            >
                <FormattedMessage id="e4c.vehicle.details.timerCharging.addTimer.button" />
            </Button>
            <TooltipRef
                tooltipContent={intl.formatMessage({
                    id: 'e4c.vehicle.details.timerCharging.addComfortTimer.tooltip',
                })}
                placement={PLACEMENT.BOTTOM_END}
                width={300}
                textAlignment="left"
                ellipsedChildren={false}
            >
                <Button
                    bsStyle={Button.PRIMARY}
                    iconName="rioglyph-climate-control"
                    className="width-100pct justify-content-start margin-left-20 btn-multiline"
                    data-testid="add-new-comfort-timer"
                    onClick={() => handleCreateTimer(true)}
                >
                    <FormattedMessage id="e4c.vehicle.details.timerCharging.addComfortTimer.button" />
                </Button>
            </TooltipRef>
        </div>
    );
};

export default CreateTimerButtons;
