import { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import detailImmediateChargingPanelModalImage from '../../../../../assets/images/detail-immediate-charging-panel-modal.png';
import UserManualPage from '../components/UserManualPage';

const ImmediateChargingItem = (): ReactElement => {
    const intl = useIntl();

    return (
        <UserManualPage
            title={<FormattedMessage id="e4c.userManual.details.immediateCharging.title" />}
            dataTestId="immediate-charging-item"
        >
            <p>
                <FormattedMessage id="e4c.userManual.details.immediateCharging.titleDescription" />
            </p>
            <div className="margin-bottom-20">
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.immediateCharging' })}
                    src={detailImmediateChargingPanelModalImage}
                    width="958"
                    height="321"
                />
            </div>
            <div className="counter color-onboarding size-small filled">
                <div>
                    <p className="margin-bottom-0 margin-top-0">
                        <span className="text-bold">
                            <FormattedMessage id="e4c.userManual.details.immediateCharging.chargeUntil" />
                        </span>{' '}
                        <FormattedMessage id="e4c.userManual.details.immediateCharging.chargeUntil.description" />
                    </p>
                </div>
                <div>
                    <p className="margin-bottom-0 margin-top-0">
                        <span className="text-bold">
                            <FormattedMessage id="e4c.userManual.details.immediateCharging.climateControl" />
                        </span>{' '}
                        <FormattedMessage id="e4c.userManual.details.immediateCharging.climateControl.description" />
                    </p>
                </div>
            </div>
        </UserManualPage>
    );
};

export default ImmediateChargingItem;
