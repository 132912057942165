import UserManualNavItem from './UserManualNavItem';
import ChargingStatusItem from './items/ChargingStatusItem';
import ClimateControlItem from './items/ClimateControlItem';
import StateOfChargeItem from './items/StateOfChargeItem';
import TimerChargingItem from './items/TimerChargingItem';
import VehicleChargingItem from './items/VehicleChargingItem';
import VehicleStatusItem from './items/VehicleStatusItem';
import { FormattedMessage } from 'react-intl';
import {
    MANUAL_CLIMATE_CONTROL_SEARCH,
    MANUAL_COMFORT_TIMER_SEARCH,
    MANUAL_DETAILS_CHARGING_SEARCH,
    MANUAL_DOWNLOAD_HISTORY_SEARCH,
    MANUAL_FLEET_EVENTS_SEARCH,
    MANUAL_IMMEDIATE_CHARGING_SEARCH,
    MANUAL_NEW_HISTORY_SEARCH,
    MANUAL_STATE_CHARGE_SEARCH,
    MANUAL_TIMER_CHARGING_MODE_SEARCH,
    MANUAL_TIMER_CHARGING_SEARCH,
    MANUAL_VEHICLE_STATUS_SEARCH,
    MANUAL_WELCOME_SEARCH,
    MANUAL_WHATS_NEW_SEARCH,
} from '../../../constants/paths/paths';
import FleetEventsItem from './items/FleetEventsItem';
import DownloadHistoryItem from './items/DownloadHistoryItem';
import WhatsNewItem from './WhatsNew/WhatsNewItem';
import NewHistoryItem from './items/NewHistoryItem';
import { ListMenuItem } from '@rio-cloud/rio-uikit/components/listMenu/ListMenuGroup';
import ImmediateChargingItem from './items/ImmediateChargingItem';
import TimerChargingModeItem from './items/TimerChargingModeItem';
import ComfortTimerItem from './items/ComfortTimerItem';

const itemsObj = [
    { title: 'VEHICLE_CHARGING', search: MANUAL_WELCOME_SEARCH, component: VehicleChargingItem },
    { title: 'WHATS_NEW', search: MANUAL_WHATS_NEW_SEARCH, component: WhatsNewItem },
    { title: 'CHARGING_STATUS', search: MANUAL_DETAILS_CHARGING_SEARCH, component: ChargingStatusItem },
    { title: 'IMMEDIATE_CHARGING', search: MANUAL_IMMEDIATE_CHARGING_SEARCH, component: ImmediateChargingItem },
    { title: 'TIMER_CHARGING_MODE', search: MANUAL_TIMER_CHARGING_MODE_SEARCH, component: TimerChargingModeItem },
    { title: 'COMFORT_TIMER', search: MANUAL_COMFORT_TIMER_SEARCH, component: ComfortTimerItem },
    { title: 'TIMER_CHARGING', search: MANUAL_TIMER_CHARGING_SEARCH, component: TimerChargingItem },
    { title: 'CLIMATE_CONTROL', search: MANUAL_CLIMATE_CONTROL_SEARCH, component: ClimateControlItem },
    { title: 'STATE_OF_CHARGE', search: MANUAL_STATE_CHARGE_SEARCH, component: StateOfChargeItem },
    { title: 'VEHICLE_STATUS', search: MANUAL_VEHICLE_STATUS_SEARCH, component: VehicleStatusItem },
    { title: 'FLEET_EVENTS', search: MANUAL_FLEET_EVENTS_SEARCH, component: FleetEventsItem },
    { title: 'DOWNLOAD_HISTORY', search: MANUAL_DOWNLOAD_HISTORY_SEARCH, component: DownloadHistoryItem },
    { title: 'NEW_HISTORY', search: MANUAL_NEW_HISTORY_SEARCH, component: NewHistoryItem },
];

const UserManualItems: ListMenuItem[] = [
    {
        group: <FormattedMessage id={'e4c.userManual.ourService'} />,
        navItems: [
            {
                key: 'VEHICLE_CHARGING',
                item: <UserManualNavItem dataKey={'overview.vehicleCharging'} />,
            },
            {
                key: 'WHATS_NEW',
                item: <UserManualNavItem dataKey={'whatsNew'} />,
            },
        ],
    },
    {
        group: <FormattedMessage id={'e4c.userManual.functionsDetail'} />,
        navItems: [
            {
                key: 'CHARGING_STATUS',
                item: <UserManualNavItem dataKey={'details.chargingStatus'} />,
            },
            {
                key: 'IMMEDIATE_CHARGING',
                item: <UserManualNavItem dataKey={'details.immediateCharging'} />,
            },
            {
                key: 'TIMER_CHARGING_MODE',
                item: <UserManualNavItem dataKey={'details.timerChargingMode'} />,
            },
            {
                key: 'COMFORT_TIMER',
                item: <UserManualNavItem dataKey={'details.comfortTimer'} />,
            },
            {
                key: 'TIMER_CHARGING',
                item: <UserManualNavItem dataKey={'details.timerCharging'} />,
            },
            {
                key: 'CLIMATE_CONTROL',
                item: <UserManualNavItem dataKey={'details.climateControl'} />,
            },
            {
                key: 'STATE_OF_CHARGE',
                item: <UserManualNavItem dataKey={'details.stateOfCharge'} />,
            },
            {
                key: 'VEHICLE_STATUS',
                item: <UserManualNavItem dataKey={'details.vehicleStatus'} />,
            },
            {
                key: 'FLEET_EVENTS',
                item: <UserManualNavItem dataKey={'details.fleetEvents'} />,
            },
            {
                key: 'NEW_HISTORY',
                item: <UserManualNavItem dataKey={'details.newHistory'} isMbooking />,
            },
            {
                key: 'DOWNLOAD_HISTORY',
                item: <UserManualNavItem dataKey={'details.downloadHistory'} isMbooking />,
            },
        ],
    },
];

export { itemsObj, UserManualItems };
