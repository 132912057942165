import { AccessToken } from '../configuration';
import Api from './types';
import { config } from '../config';
import { isDevEnvironment, isSuccessful } from './utils';
import { getLatestEvents } from './events';

const getMonitoringVehicles = async (token: AccessToken): Promise<Response> => {
    const measurements = [
        'state_of_charge',
        'remaining_range',
        'energy_level',
        'driving_state',
        'charging_state',
        'ambient_temperature',
        'inside_temperature',
    ];

    const params = `fields=(${measurements.join(',')})`;
    const endpoint = `${config.backend.OTA_CHARGING_MONITORING_SERVICE}/bff/assets?${params}`;

    const bearerToken = isDevEnvironment() ? await getLocalAccessToken() : token;
    return fetch(endpoint, {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        },
    });
};

const getStateVehicles = async (token: AccessToken): Promise<Response> => {
    const endpoint = `${config.backend.OTA_CHARGING_STATE_SERVICE}/assets`;
    const bearerToken = isDevEnvironment()
        ? await getLocalAccessToken(`${config.backend.OTA_CHARGING_STATE_SERVICE}`, 'http://localhost:8080')
        : token;

    return fetch(endpoint, {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        },
    });
};

export const getLocalAccessToken = async (
    serviceURL = `${config.backend.OTA_CHARGING_MONITORING_SERVICE}`,
    iss = 'http://localhost:8081'
): Promise<string> => {
    const tokenEndpoint = `${serviceURL}/oauth/token`;
    const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            sub: 'test:38675105-97ab-48be-b88d-9763a4cea6f8',
            scope: 'ota-charging.read ota-charging.write',
            iss,
            iat: 1571922764,
            exp: 2572452024,
            account: '237c58e3-60b5-4442-a9ec-8641ea39325b',
            tenant: 'prod',
            azp: 'client-id',
        }),
    });

    return isSuccessful(response) ? response.text() : 'valid-mocked-oauth-bogus-token';
};

const ServicesApi: Api = {
    getMonitoringVehicles: (token: AccessToken) => getMonitoringVehicles(token),
    getStateVehicles: (token: AccessToken) => getStateVehicles(token),
    getLatestEvents: (token: AccessToken) => getLatestEvents(token),
};

export default ServicesApi;
