import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import ServicesApi from '../../../../api/Api';
import mappingVehicleChargingFromServer from '../helpers/mappingVehicleChargingFromServer';
import { MonitoringServiceResponse } from '../models/api/monitoringServiceResponse';
import { StateServiceResponse } from '../models/api/stateServiceResponse';
import { VehicleChargingData } from '../models/VehicleChargingData';
import { AccessToken } from '../../../../configuration';
import {
    fetchVehicleChargingConfigurations,
    patchTimers,
    postImmediateConfigurations,
    postTimerConfigurations,
    putTimerConfigurations,
} from '../../../../api/vehicleCharging';
import {
    ImmediateConfigurationRequest,
    TimerPatchRequest,
    TimerRequest,
    TimerResponse,
    VehicleChargingConfigurationResponse,
} from './types';
import {
    ImmediateConfiguration,
    Timer,
    VehicleChargingConfiguration,
} from '../components/details/vehicleDetails/ChargingMode/vehicleChargingConfigurationTypes';
import { useMemo } from 'react';

const TEN_MINUTES = 300000 * 2;
export const ASSET_NOT_FOUND_ERROR = 'Asset not found!';

const queryOptions = (accessToken?: string | null) => ({ refetchInterval: TEN_MINUTES, enabled: !!accessToken });

export const useVehicleChargingAPI = (
    refreshTableAfterSaveDetails: boolean,
    accessToken?: string | null
): { isLoading: boolean; isError: boolean; vehicleChargingData: VehicleChargingData[] } => {
    const monitor = useQuery<unknown, Error, MonitoringServiceResponse>(
        ['monitor-vc', refreshTableAfterSaveDetails],
        () => ServicesApi.getMonitoringVehicles(accessToken).then(res => res.json()),
        queryOptions(accessToken)
    );
    const state = useQuery<unknown, Error, StateServiceResponse>(
        ['state-vc', refreshTableAfterSaveDetails],
        () => ServicesApi.getStateVehicles(accessToken).then(res => res.json()),
        queryOptions(accessToken)
    );

    const isLoading = monitor.isLoading || state.isLoading;
    const isError = monitor.isError || state.isError;

    const vehicleChargingData: VehicleChargingData[] = mappingVehicleChargingFromServer(monitor.data, state.data);

    return { isLoading, isError, vehicleChargingData };
};

export const useGetVehicleChargingConfiguration = (
    accessToken: AccessToken,
    assetId: string
): {
    isLoading: boolean;
    isError: boolean;
    error: unknown;
    vehicleChargingConfiguration: VehicleChargingConfiguration | null;
} => {
    const { isLoading, isError, error, data } = useQuery(
        [`vehicle-charging-${assetId}`],
        () => fetchVehicleChargingConfigurations(accessToken, assetId).then(res => res.json()),
        {
            retry: (failureCount: number, errorCode: any) => {
                if (errorCode?.message?.includes(ASSET_NOT_FOUND_ERROR)) {
                    return false;
                }
                return failureCount < 3;
            },
        }
    );
    const vehicleChargingConfiguration = useMemo(() => (data ? mapVehicleChargingConfiguration(data) : null), [data]);
    return { isLoading, isError, error, vehicleChargingConfiguration };
};

export const usePostImmediateConfigurations = (accessToken: AccessToken, assetId: string) => {
    const queryClient = useQueryClient();
    return useMutation(
        (configuration: ImmediateConfiguration) => {
            const body = createImmediateRequestBody(configuration);
            return postImmediateConfigurations(accessToken, assetId, JSON.stringify(body));
        },
        { ...invalidateQueriesOnSuccess(queryClient, assetId) }
    );
};

export const useCreateTimerConfigurations = (accessToken: AccessToken, assetId: string) => {
    const queryClient = useQueryClient();
    return useMutation(
        (timer: Timer) => {
            const body = createTimerRequestBody(timer);
            return postTimerConfigurations(accessToken, assetId, JSON.stringify(body));
        },
        { ...invalidateQueriesOnSuccess(queryClient, assetId) }
    );
};

export const useEditTimerConfigurations = (accessToken: AccessToken, assetId: string) => {
    const queryClient = useQueryClient();
    return useMutation(
        (timer: Timer) => {
            const body = createTimerRequestBody(timer);
            return putTimerConfigurations(accessToken, assetId, timer.id, JSON.stringify(body));
        },
        { ...invalidateQueriesOnSuccess(queryClient, assetId) }
    );
};

export const usePatchTimerConfigurations = (
    accessToken: AccessToken,
    assetId: string,
    timers: Timer[],
    unsavedTimers: Timer[]
) => {
    const queryClient = useQueryClient();
    return useMutation(
        () => {
            const patchArray = createPatchArray(timers, unsavedTimers);
            return patchTimers(accessToken, assetId, JSON.stringify(patchArray));
        },
        { ...invalidateQueriesOnSuccess(queryClient, assetId) }
    );
};

const invalidateQueriesOnSuccess = (queryClient: QueryClient, assetId: string) => ({
    onSuccess: () => queryClient.invalidateQueries(`vehicle-charging-${assetId}`),
});

const createPatchArray = (timers: Timer[], updatedTimers: Timer[]): TimerPatchRequest[] | [] => {
    const patchArray: TimerPatchRequest[] = [];
    timers.forEach(timer => {
        const unsavedTimer = updatedTimers.find(updatedTimer => timer.id === updatedTimer.id);
        if (unsavedTimer) {
            if (timer.enabled !== unsavedTimer.enabled) {
                patchArray.push({
                    op: 'replace',
                    path: `/${timer.id}/enabled`,
                    value: unsavedTimer.enabled,
                });
            }
        } else {
            patchArray.push({
                op: 'remove',
                path: `/${timer.id}`,
            });
        }
    });
    return patchArray;
};

const mapTimersFromResponse = (timers: TimerResponse[]): Timer[] => {
    return timers.map(timer => {
        return {
            id: timer.id,
            weekdays: timer.weekdays,
            departureTimeHour: timer.departure_time_hour,
            departureTimeMinute: timer.departure_time_minute,
            zoneId: timer.zone_id,
            climateMode: timer.climate_mode,
            chargeUntil: timer.charge_until,
            readyToDriveDuration: timer.ready_to_drive_duration,
            weeklyRepeat: timer.weekly_repeat,
            enabled: timer.enabled,
            nextDeparturesTimeStatus: timer.next_departures_time_status.map(nextDepartureTime => ({
                status: nextDepartureTime.status,
                departureTime: new Date(Date.parse(nextDepartureTime.departure_time)),
            })),
            comfortTimer: timer.comfort_timer,
        };
    });
};

const mapVehicleChargingConfiguration = (data: VehicleChargingConfigurationResponse): VehicleChargingConfiguration => {
    return {
        assetId: data.asset_id,
        chargingMode: data.charging_mode,
        immediateConfiguration: {
            climateMode: data.configurations.immediate.climate_mode,
            chargeUntil: data.configurations.immediate.charge_until,
        },
        timers: mapTimersFromResponse(data.configurations.timer),
        userAction: {
            postImmediateConfigurations: data._actions?.post_immediate_configurations,
            postTimerConfigurations: data._actions?.post_timer_configurations,
        },
    };
};

const createImmediateRequestBody = (configuration: ImmediateConfiguration): ImmediateConfigurationRequest => ({
    climate_mode: configuration.climateMode,
    charge_until: configuration.chargeUntil,
});

const createTimerRequestBody = (timer: Timer): TimerRequest => ({
    enabled: timer.enabled,
    weekdays: timer.weekdays,
    climate_mode: timer.climateMode,
    weekly_repeat: timer.weeklyRepeat,
    departure_time_hour: timer.departureTimeHour,
    departure_time_minute: timer.departureTimeMinute,
    charge_until: timer.chargeUntil,
    ready_to_drive_duration: timer.readyToDriveDuration,
    zone_id: timer.zoneId,
    comfort_timer: timer.comfortTimer,
});
