import { AccessToken } from '../../../../../configuration';
import {
    CLEAN_STATE,
    CleanStateAction,
    CLIMATE_MODE_CHANGED,
    ClimateModeChangedAction,
    CONFIGURATION_CHANGE_CONFIRMED,
    ConfigurationChangeConfirmedAction,
    DAY_FROM_TIMER_REMOVED,
    DAY_TO_TIMER_ADDED,
    DayFromTimerRemovedAction,
    DayToTimerAddedAction,
    IMMEDIATE_CONFIGURATION_SENDING_FAILED,
    IMMEDIATE_CONFIGURATION_SENDING_STARTED,
    IMMEDIATE_CONFIGURATION_SENDING_SUCCESSFUL,
    READY_TO_DRIVE_DURATION_CHANGED,
    ReadyToDriveDurationChangedAction,
    SHOW_CONFIGURATION_CHANGE_CONFIRM_DIALOG,
    SHOW_SWITCH_TO_IMMEDIATE_CHARGING_DIALOG,
    ShowConfigurationChangeConfirmDialogAction,
    ShowSwitchToImmediateChargingDialogAction,
    SWITCH_TO_IMMEDIATE_CHARGING_CONFIRMED,
    SwitchToImmediateChargingConfirmedAction,
    TARGET_STATE_OF_CHARGE_CHANGED,
    TargetStateOfChargeChangedAction,
    TIMER_CONFIGURATION_SENDING_FAILED,
    TIMER_CONFIGURATION_SENDING_STARTED,
    TIMER_CONFIGURATION_SENDING_SUCCESSFUL,
    TIMER_DEPARTURE_TIME_CHANGED,
    TIMER_ENABLED_CHANGED,
    TimerDepartureTimeChangedAction,
    TimerEnabledChangedAction,
    TOGGLE_SENDING_CHARGING_CONFIG_SUCCEEDED,
    ToggleSendingChargingConfigSucceededAction,
    VEHICLE_CHARGING_MODE_CHANGED,
    VEHICLE_DETAILS_LOADING_FAILED,
    VEHICLE_DETAILS_LOADING_STARTED,
    VEHICLE_DETAILS_LOADING_SUCCESSFUL,
    VEHICLE_TIMER_CONFIGURATION_TIMER_ADDED,
    VEHICLE_TIMER_CONFIGURATION_TIMER_REMOVED,
    VehicleChargingModeChangedAction,
    VehicleDetailsThunkAction,
    VehicleDetailsThunkDispatch,
    VehicleTimerConfigurationTimerAddedAction,
    VehicleTimerConfigurationTimerRemovedAction,
} from './types';
import { config } from '../../../../../config';
import { Timer, TimerConfiguration, VehicleDetails, Weekday } from '../../components/details/vehicleDetails/types';
import _ from 'lodash';
import { mapAsset } from '../../../actions/vehicles/AssetMapper';
import { DEPARTURE_TIMES_PREVIEW_LENGTH } from '../../components/details/vehicleDetails/vehicleDetailsHelper';
import isEmpty from 'lodash/fp/isEmpty';
import moment from 'moment-timezone';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../../utils/googleTagManager';
import ElectricVehicle from '../../models/ElectricVehicle';
import { createVehicleDetailsWithUnconfigurableVehicle } from './VehicleDetailsActionsHelper';
import { ChargingModeEnum } from '../../types';
import { ErrorCodeEnum } from '../../../common/enums/ErrorCode';
import { currentTimezone } from '../../../utils/timezone';
import { isDevEnvironment } from '../../../../../api/utils';
import { getLocalAccessToken } from '../../../../../api/Api';

export const fetchVehicleDetails = (
    accessToken: AccessToken,
    assetId: string,
    vehicle: ElectricVehicle
): VehicleDetailsThunkAction<void> => {
    return async (dispatch: VehicleDetailsThunkDispatch): Promise<void> => {
        dispatch({
            type: VEHICLE_DETAILS_LOADING_STARTED,
        });

        if (vehicle.isChargingConfigurable()) {
            const vehicleDetailsEndpoint = `${config.backend.OTA_CHARGING_STATE_SERVICE}/assets/${assetId}?embed=(timer_configurations, charging_configurations)`;
            const bearerToken = isDevEnvironment()
                ? await getLocalAccessToken(`${config.backend.OTA_CHARGING_STATE_SERVICE}`, 'http://localhost:8080')
                : accessToken;
            const response = await fetch(vehicleDetailsEndpoint, {
                headers: {
                    Authorization: `Bearer ${bearerToken}`,
                },
            });
            if (response.status === 200) {
                const json = await response.json();
                const mappedVehicle = mapAsset(json);
                const timerConfiguration = _.get(json, '_embedded.timer_configurations.items', [])[0];
                const userAction = _.get(json, '_actions', []);
                let chargingConfigurations = _.get(json, '_embedded.charging_configurations.items', []).slice(
                    0,
                    DEPARTURE_TIMES_PREVIEW_LENGTH
                );

                if (!isEmpty(chargingConfigurations)) {
                    const mappedChargingConfigurations = chargingConfigurations.map((chargingConfig: any) => {
                        const departureTimeWithTimezone = moment
                            .tz(chargingConfig.departure_time, currentTimezone)
                            .toDate();
                        return {
                            asset_id: chargingConfig.asset_id,
                            status: chargingConfig.status,
                            charging_mode: chargingConfig.charging_mode,
                            departure_time: departureTimeWithTimezone,
                            climate_mode: chargingConfig.climate_mode,
                            target_state_of_charge: chargingConfig.target_state_of_charge,
                        };
                    });
                    chargingConfigurations = mappedChargingConfigurations;
                }
                if (timerConfiguration) {
                    const mappedTimers: Timer[] = timerConfiguration.timers.map((timer: any) => {
                        const formattedHour =
                            timer.departure_time_hour <= 9
                                ? `0${timer.departure_time_hour}`
                                : timer.departure_time_hour;
                        const formattedMinute =
                            timer.departure_time_minute <= 9
                                ? `0${timer.departure_time_minute}`
                                : timer.departure_time_minute;
                        return {
                            weekdays: timer.weekdays,
                            departure_time: `${formattedHour}:${formattedMinute}`,
                            enabled: timer.enabled,
                            zone_id: timerConfiguration.zone_id,
                        };
                    });
                    timerConfiguration.timers = mappedTimers;
                }
                const vehicleDetails: VehicleDetails = {
                    vehicle: mappedVehicle,
                    selectedVehicleBeforeChanges: mappedVehicle,
                    timerConfiguration,
                    timerConfigurationBeforeChanges: timerConfiguration,
                    chargingConfigurations,
                    userAction,
                };

                dispatch({
                    type: VEHICLE_DETAILS_LOADING_SUCCESSFUL,
                    payload: vehicleDetails,
                });
            } else {
                if (response.status === 404) {
                    dispatch({
                        type: VEHICLE_DETAILS_LOADING_FAILED,
                        payload: ErrorCodeEnum.NOT_FOUND,
                    });
                } else {
                    dispatch({
                        type: VEHICLE_DETAILS_LOADING_FAILED,
                        payload: ErrorCodeEnum.OTHER,
                    });
                }
            }
        } else {
            dispatch({
                type: VEHICLE_DETAILS_LOADING_SUCCESSFUL,
                payload: createVehicleDetailsWithUnconfigurableVehicle(vehicle),
            });
        }
    };
};

export const vehicleChargingModeChanged = (chargingMode: ChargingModeEnum): VehicleChargingModeChangedAction => {
    const trackingAction =
        chargingMode === ChargingModeEnum.IMMEDIATE
            ? TrackingAction.CLICK_IMMEDIATE_CHARGING
            : TrackingAction.CLICK_TIMER_CHARGING;
    gtmUtil.pushGaEvent(createGoogleAnalyticsEvent(TrackingCategory.CHARGING_CONFIGURATION, trackingAction));
    return {
        type: VEHICLE_CHARGING_MODE_CHANGED,
        payload: chargingMode,
    };
};

export const vehicleTimerConfigurationTimerAdded = (timer: Timer): VehicleTimerConfigurationTimerAddedAction => {
    gtmUtil.pushGaEvent(createGoogleAnalyticsEvent(TrackingCategory.CHARGING_CONFIGURATION, TrackingAction.ADD_TIMER));
    return {
        type: VEHICLE_TIMER_CONFIGURATION_TIMER_ADDED,
        payload: timer,
    };
};

export const vehicleTimerConfigurationTimerRemoved = (timerNo: number): VehicleTimerConfigurationTimerRemovedAction => {
    return {
        type: VEHICLE_TIMER_CONFIGURATION_TIMER_REMOVED,
        payload: timerNo,
    };
};

export const sendImmediateConfiguration = (
    accessToken: AccessToken,
    vehicleId: string,
    climateMode: boolean,
    targetStateOfCharge: number
): VehicleDetailsThunkAction<void> => {
    return async (dispatch: VehicleDetailsThunkDispatch): Promise<void> => {
        dispatch({
            type: IMMEDIATE_CONFIGURATION_SENDING_STARTED,
        });
        gtmUtil.pushGaEvent(
            createGoogleAnalyticsEvent(
                TrackingCategory.CHARGING_CONFIGURATION,
                TrackingAction.SEND_IMMEDIATE_CONFIGURATION
            )
        );
        const bearerToken = isDevEnvironment()
            ? await getLocalAccessToken(`${config.backend.OTA_CHARGING_STATE_SERVICE}`, 'http://localhost:8080')
            : accessToken;
        const immediateChargingConfigEndpoint = `${config.backend.OTA_CHARGING_STATE_SERVICE}/assets/${vehicleId}/immediate-configurations`;
        const response = await fetch(immediateChargingConfigEndpoint, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                climate_mode: climateMode,
                target_state_of_charge: targetStateOfCharge,
            }),
        });
        if (response.status === 201) {
            dispatch({
                type: IMMEDIATE_CONFIGURATION_SENDING_SUCCESSFUL,
            });
        } else {
            dispatch({
                type: IMMEDIATE_CONFIGURATION_SENDING_FAILED,
            });
        }
    };
};

export const sendTimerConfiguration = (
    accessToken: AccessToken,
    vehicleId: string,
    timerConfiguration: TimerConfiguration
): VehicleDetailsThunkAction<void> => {
    return async (dispatch: VehicleDetailsThunkDispatch): Promise<void> => {
        dispatch({
            type: TIMER_CONFIGURATION_SENDING_STARTED,
        });
        gtmUtil.pushGaEvent(
            createGoogleAnalyticsEvent(TrackingCategory.CHARGING_CONFIGURATION, TrackingAction.SEND_TIMER_CONFIGURATION)
        );
        const timersToSend = timerConfiguration.timers.map((timer: Timer) => {
            const parsedDepartureTime = new Date(`1970-01-01T${timer.departure_time}`);

            return {
                weekdays: timer.weekdays,
                enabled: timer.enabled,
                departure_time_hour: parsedDepartureTime.getHours(),
                departure_time_minute: parsedDepartureTime.getMinutes(),
            };
        });

        const bearerToken = isDevEnvironment()
            ? await getLocalAccessToken(`${config.backend.OTA_CHARGING_STATE_SERVICE}`, 'http://localhost:8080')
            : accessToken;
        const timerChargingConfigEndpoint = `${config.backend.OTA_CHARGING_STATE_SERVICE}/assets/${vehicleId}/timer-configurations`;
        const response = await fetch(timerChargingConfigEndpoint, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                climate_mode: timerConfiguration.climate_mode,
                target_state_of_charge: timerConfiguration.target_state_of_charge,
                zone_id: currentTimezone,
                timers: timersToSend,
                ready_to_drive_duration: timerConfiguration.ready_to_drive_duration,
            }),
        });

        if (response.status === 201) {
            dispatch({
                type: TIMER_CONFIGURATION_SENDING_SUCCESSFUL,
            });
        } else {
            dispatch({
                type: TIMER_CONFIGURATION_SENDING_FAILED,
            });
        }
    };
};

export const onAddDayToTimer = (day: Weekday, timerNo: number): DayToTimerAddedAction => {
    return {
        type: DAY_TO_TIMER_ADDED,
        payload: {
            day,
            timerNo,
        },
    };
};

export const onRemoveDayFromTimer = (day: Weekday, timerNo: number): DayFromTimerRemovedAction => {
    return {
        type: DAY_FROM_TIMER_REMOVED,
        payload: {
            day,
            timerNo,
        },
    };
};

export const onDepartureTimeChange = (departureTime: string, timerNo: number): TimerDepartureTimeChangedAction => {
    return {
        type: TIMER_DEPARTURE_TIME_CHANGED,
        payload: {
            departureTime,
            timerNo,
        },
    };
};

export const onTimerEnabledChange = (timerNo: number): TimerEnabledChangedAction => {
    return {
        type: TIMER_ENABLED_CHANGED,
        payload: timerNo,
    };
};

export const onConfirmConfigurationChange = (): ConfigurationChangeConfirmedAction => {
    return {
        type: CONFIGURATION_CHANGE_CONFIRMED,
    };
};

export const onShowConfigurationChangeConfirmDialog = (show: boolean): ShowConfigurationChangeConfirmDialogAction => {
    return {
        type: SHOW_CONFIGURATION_CHANGE_CONFIRM_DIALOG,
        payload: show,
    };
};

export const onShowSwitchToImmediateChargingDialog = (show: boolean): ShowSwitchToImmediateChargingDialogAction => {
    return {
        type: SHOW_SWITCH_TO_IMMEDIATE_CHARGING_DIALOG,
        payload: show,
    };
};

export const onConfirmSwitchToImmediateCharging = (confirmed: boolean): SwitchToImmediateChargingConfirmedAction => {
    return {
        type: SWITCH_TO_IMMEDIATE_CHARGING_CONFIRMED,
        payload: confirmed,
    };
};

export const onClimateModeChange = (climateMode: boolean): ClimateModeChangedAction => {
    const trackingAction = climateMode ? TrackingAction.SWITCH_CLIMATE_MODE_ON : TrackingAction.SWITCH_CLIMATE_MODE_OFF;
    gtmUtil.pushGaEvent(createGoogleAnalyticsEvent(TrackingCategory.CHARGING_CONFIGURATION, trackingAction));
    return {
        type: CLIMATE_MODE_CHANGED,
        payload: climateMode,
    };
};

export const onTargetStateOfChargeChange = (targetStateOfChange: number): TargetStateOfChargeChangedAction => {
    gtmUtil.pushGaEvent(
        createGoogleAnalyticsEvent(TrackingCategory.CHARGING_CONFIGURATION, TrackingAction.CLICK_TARGET_STATE_OF_CHARGE)
    );
    return {
        type: TARGET_STATE_OF_CHARGE_CHANGED,
        payload: targetStateOfChange,
    };
};

export const toggleSendingChargingConfigSucceeded = (
    sendingChargingConfigSucceeded: boolean
): ToggleSendingChargingConfigSucceededAction => {
    return {
        type: TOGGLE_SENDING_CHARGING_CONFIG_SUCCEEDED,
        payload: sendingChargingConfigSucceeded,
    };
};

export const onReadyToDriveDurationChange = (readyToDriveDuration: number): ReadyToDriveDurationChangedAction => {
    gtmUtil.pushGaEvent(
        createGoogleAnalyticsEvent(
            TrackingCategory.CHARGING_CONFIGURATION,
            TrackingAction.CHANGE_READY_TO_DRIVE_DURATION
        )
    );
    return {
        type: READY_TO_DRIVE_DURATION_CHANGED,
        payload: readyToDriveDuration,
    };
};

export const cleanState = (): CleanStateAction => {
    return {
        type: CLEAN_STATE,
    };
};
