import { AccessToken, EventSources } from '../configuration';
import { config } from '../config';
import { isDevEnvironment } from './utils';
import { getLocalAccessToken } from './Api';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { EventListeners } from '../features/app/overview/queries/subscribeToVehicleEventUpdates';

const NOTIFICATIONS_URL = config.backend.NOTIFICATIONS_SERVICE_URL;
const PAYLOAD_PATCH = [{ op: 'replace', path: '/status', value: 'seen' }];
const HEARTBEAT_TIMEOUT = 65; // Should be bigger than the reconnect time from the server

const eventSources: EventSources = {};

const eventsEndpoint = () => `${NOTIFICATIONS_URL}/events`;

const severityLevelEndpoint = (severityLevel?: string[]) =>
    severityLevel?.length ? `/level/${severityLevel.toString()}` : '';

const generateHeaders = async (accessToken: AccessToken, contentType?: string) => {
    const bearerToken = isDevEnvironment()
        ? await getLocalAccessToken(`${config.backend.NOTIFICATIONS_SERVICE_URL}`, 'http://localhost:8082')
        : accessToken;
    return {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': contentType ? contentType : 'application/json',
    };
};

export const getEventsWithPagination = async (
    pageParam: string,
    accessToken: AccessToken,
    status: string,
    severityLevel: string[]
): Promise<Response> => {
    const eventStatusEndpoint = `${eventsEndpoint()}/status/${status}`;
    const cursor = pageParam ? `?cursor=${pageParam}` : '';
    const getEventsWithPaginationURL = `${eventStatusEndpoint}${severityLevelEndpoint(severityLevel)}${cursor}`;
    const headers = await generateHeaders(accessToken);

    return fetch(getEventsWithPaginationURL, {
        headers,
    });
};

export const markAsSeenPatch = async (
    accessToken: AccessToken,
    eventId?: string,
    markAll?: boolean,
    severityLevel?: string[],
    lastEventId?: number
): Promise<Response> => {
    const lastEventIdMarked = lastEventId ? `?lastId=${lastEventId}` : '';
    const markAllEventsAsSeenURL = `${eventsEndpoint()}${severityLevelEndpoint(severityLevel)}${lastEventIdMarked}`;
    const markAsSeenSpecificEventURL = `${eventsEndpoint()}/${eventId}`;
    const markAsSeenURL = markAll ? markAllEventsAsSeenURL : markAsSeenSpecificEventURL;
    const headers = await generateHeaders(accessToken, 'application/json-patch+json');

    return fetch(markAsSeenURL, {
        headers,
        method: 'PATCH',
        body: JSON.stringify(PAYLOAD_PATCH),
    });
};

export const deleteEvents = async (
    accessToken: AccessToken,
    eventId?: string,
    deleteAll?: boolean,
    severityLevel?: string[]
): Promise<Response> => {
    const deleteAllEventsURL = `${eventsEndpoint()}${severityLevelEndpoint(severityLevel)}`;
    const deleteSpecificEventURL = `${eventsEndpoint()}/${eventId}`;
    const endpoint = deleteAll ? deleteAllEventsURL : deleteSpecificEventURL;
    const headers = await generateHeaders(accessToken);

    return fetch(endpoint, {
        headers,
        method: 'DELETE',
    });
};

export const closeEventSource = (eventSourceName: string) => {
    if (eventSources[eventSourceName] !== undefined) {
        eventSources[eventSourceName].close();
        delete eventSources[eventSourceName];
    }
};

export const setUpEventSource = async (
    accessToken: AccessToken,
    eventSourceName: string,
    eventListeners: EventListeners
) => {
    closeEventSource(eventSourceName); // will close only if already exists
    const headers = await generateHeaders(accessToken, 'text/event-stream');
    const options = {
        headers,
        heartbeatTimeout: HEARTBEAT_TIMEOUT * 1000,
    };

    // Since proxy is not working for SSE, we have to use direct url for testing the backend locally
    const url = isDevEnvironment() ? 'http://localhost:8082' : NOTIFICATIONS_URL;
    const eventsSubscriptionURL = `${url}/events/subscribe`;

    const eventSource = new EventSourcePolyfill(eventsSubscriptionURL, options);

    Object.entries(eventListeners).forEach(([key, eventListener]) => eventSource.addEventListener(key, eventListener));

    eventSource.onerror = (err: any) => {
        if (err.error) {
            closeEventSource(eventSourceName);
        }
    };
    eventSources[eventSourceName] = eventSource;
};

interface EventsSettingsArgs {
    accessToken: AccessToken;
    method?: 'GET' | 'PATCH';
    contentType?: string;
    body?: string;
}

export const fetchEventsSettings = async ({
    accessToken,
    method = 'GET',
    contentType,
    body,
}: EventsSettingsArgs): Promise<Response> => {
    const eventSettingsEndpoint = `${eventsEndpoint()}-settings`;
    const headers = await generateHeaders(accessToken, contentType);

    const response = await fetch(eventSettingsEndpoint, {
        headers,
        body,
        method,
    });
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return response;
};

export const getLatestEvents = async (token: AccessToken): Promise<Response> => {
    const latestEventsEndpoint = `${eventsEndpoint()}/latest`;
    const headers = await generateHeaders(token);

    return fetch(latestEventsEndpoint, { headers });
};
