import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/Button';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { VehicleDetailsContext } from '../../../../../../../../../providers/contexts';
import { Timer, Weekday } from '../../vehicleChargingConfigurationTypes';
import { currentTimezone } from '../../../../../../../utils/timezone';
import {
    useCreateTimerConfigurations,
    useEditTimerConfigurations,
} from '../../../../../../queries/useVehicleChargingAPI';
import { useSelector } from 'react-redux';
import { State } from '../../../../../../../../../types';
import { getAccessToken } from '../../../../../../../../../configuration';
import TimerSettings from './TimerSettings';
import notVanishingNotificationWarning from '../../../notVanishingNotificationWarning';

const IDENTICAL_TIMERS_ERROR = 'Timer configuration is invalid';

export interface TimerConfig {
    id?: string;
    weekdays: Weekday[];
    departureTime: string;
    weeklyRepeat: boolean;
    chargeUntil?: number;
    climateMode: boolean;
    readyToDriveDuration: number;
    enabled: boolean;
    comfortTimer: boolean;
}

const TimerSetupForm = ({ assetId }: { assetId: string }) => {
    const intl = useIntl();

    const {
        hasUnsavedChanges,
        setHasUnsavedChanges,
        setSendingChargingConfigSucceeded,
        setSendingChargingConfigFailed,
        hideTimerForm,
        editingTimer: initialConfiguration,
        dialog,
    } = useContext(VehicleDetailsContext);

    const [timerConfig, setTimerConfig] = useState(initialConfiguration);

    const accessToken = useSelector((state: State) => getAccessToken(state));
    const { mutate: mutateCreateTimer } = useCreateTimerConfigurations(accessToken, assetId);
    const { mutate: mutateEditTimer } = useEditTimerConfigurations(accessToken, assetId);

    // Set the unsaved changes flag when the configuration values are modified
    useEffect(() => {
        const hasChanges = Object.entries(timerConfig).some(
            ([key, value]) => value !== initialConfiguration[key as keyof TimerConfig]
        );
        setHasUnsavedChanges(hasChanges);
    }, [timerConfig, initialConfiguration, setHasUnsavedChanges]);

    const hasWeekdaysSelected = timerConfig.weekdays.length !== 0;

    const handleSaveTimer = () => {
        if (!hasWeekdaysSelected) {
            Notification.error(
                intl.formatMessage({ id: 'e4c.vehicle.details.error.invalidTimer.description' }),
                intl.formatMessage({ id: 'e4c.vehicle.details.error.invalidTimer.title' }),
                3000
            );
            return;
        }

        const [hour, minute] = timerConfig.departureTime.split(':');
        const newTimer: Timer = {
            id: timerConfig.id ?? '',
            weekdays: timerConfig.weekdays,
            departureTimeHour: parseInt(hour, 10),
            departureTimeMinute: parseInt(minute, 10),
            zoneId: currentTimezone,
            climateMode: timerConfig.climateMode,
            chargeUntil: timerConfig.chargeUntil,
            readyToDriveDuration: timerConfig.readyToDriveDuration,
            weeklyRepeat: timerConfig.weeklyRepeat,
            enabled: timerConfig.enabled,
            nextDeparturesTimeStatus: [],
            comfortTimer: timerConfig.comfortTimer,
        };

        const mutationFunction = timerConfig.id ? mutateEditTimer : mutateCreateTimer;

        mutationFunction(newTimer, {
            onSuccess: () => {
                setHasUnsavedChanges(false);
                hideTimerForm();
                setSendingChargingConfigSucceeded(true);
            },
            onError: error => {
                if ((error as Error).message === IDENTICAL_TIMERS_ERROR) {
                    notVanishingNotificationWarning(
                        <FormattedMessage id={'e4c.vehicle.details.warning.identicalTimers'} />
                    );
                } else {
                    setSendingChargingConfigFailed(true);
                }
            },
        });
    };

    const handleCloseForm = () => {
        if (hasUnsavedChanges) {
            dialog.openDialog(() => {
                hideTimerForm();
                setHasUnsavedChanges(false);
            });
        } else {
            hideTimerForm();
        }
    };

    return (
        <div
            className="border padding-y-10 padding-x-25 position-relative margin-top-20"
            data-testid="timer-setup-form"
        >
            <Button
                bsStyle={Button.MUTED}
                bsSize={Button.XS}
                iconName="rioglyph rioglyph-remove"
                iconOnly
                className="position-absolute top-10 right-10"
                onClick={handleCloseForm}
                label="close-button"
                aria-label="close-button"
            />
            <TimerSettings
                timerConfig={timerConfig}
                setTimerConfig={setTimerConfig}
                hasWeekdaysSelected={hasWeekdaysSelected}
            />
            <div className="display-flex justify-content-end margin-top-25" data-testid="save-timer">
                <Button
                    bsStyle={Button.PRIMARY}
                    iconName="rioglyph rioglyph-time-alt"
                    onClick={handleSaveTimer}
                    disabled={!hasUnsavedChanges}
                >
                    {intl.formatMessage({ id: 'e4c.vehicle.details.saveTimer.button' })}
                </Button>
            </div>
        </div>
    );
};

export default TimerSetupForm;
