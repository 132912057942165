import { AccessToken } from '../../../../../configuration';
import {
    VEHICLES_STATISTICS_LOADING_FAILED,
    VEHICLES_STATISTICS_LOADING_STARTED,
    VEHICLES_STATISTICS_LOADING_SUCCESSFUL,
    VehiclesStatisticsThunkAction,
    VehiclesStatisticsThunkDispatch,
} from './types';
import { config } from '../../../../../config';
import _ from 'lodash';
import { VehicleStatistics } from '../../types';
import { getEndDate, getStartDate } from '../../../utils/dates';
import { getLocalAccessToken } from '../../../../../api/Api';

let token = '';

export const fetchVehiclesStatistics = (accessToken: AccessToken): VehiclesStatisticsThunkAction<void> => {
    return async (dispatch: VehiclesStatisticsThunkDispatch): Promise<void> => {
        dispatch({
            type: VEHICLES_STATISTICS_LOADING_STARTED,
        });
        if (import.meta.env.DEV) {
            token = await getLocalAccessToken();
        }
        const assetsStatisticsEndpoint = `${config.backend.OTA_CHARGING_MONITORING_SERVICE}/assets/statistics`
            .concat(`?start_date=${getStartDate()}`)
            .concat(`&end_date=${getEndDate()}`);
        const response = await fetch(assetsStatisticsEndpoint, {
            headers: {
                Authorization: `Bearer ${import.meta.env.DEV ? token : accessToken}`,
            },
        });

        if (response.status === 200) {
            const json = await response.json();
            const vehiclesStatistics: VehicleStatistics[] = json.items.map((vehicleStatistics: any) => {
                return {
                    vehicleId: vehicleStatistics.asset_id,
                    history: _.get(vehicleStatistics, 'history', []).map((historyData: any) => {
                        return {
                            date: historyData.date,
                            drivenDistance: historyData.driven_distance,
                            energyConsumption: historyData.energy_consumption,
                            powertrainEnergyConsumption: historyData.power_train_energy_consumption,
                            auxiliaryEnergyConsumption: historyData.aux_energy_consumption,
                            amsEnergyConsumption: historyData.ams_energy_consumption,
                            recuperatedEnergy: historyData.recuperated_energy,
                        };
                    }),
                };
            });
            dispatch({
                type: VEHICLES_STATISTICS_LOADING_SUCCESSFUL,
                payload: vehiclesStatistics,
            });
        } else {
            dispatch({
                type: VEHICLES_STATISTICS_LOADING_FAILED,
            });
        }
    };
};
