import { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import detailTimerChargingCreationPanelModalImage from '../../../../../assets/images/detail-timer-charging-mode-creation-panel-modal.png';
import detailTimerChargingEditionPanelModalImage from '../../../../../assets/images/detail-timer-charging-mode-edition-panel-modal.png';
import UserManualPage from '../components/UserManualPage';

const CounterItem = ({ count, id }: { count: number; id: string }) => (
    <div data-count={count}>
        <p className="margin-y-0">
            <span className="text-bold">
                <FormattedMessage id={`e4c.userManual.details.timerChargingMode.${id}`} />
            </span>{' '}
            <FormattedMessage id={`e4c.userManual.details.timerChargingMode.${id}.description`} />
        </p>
    </div>
);

const TimerChargingModeItem = (): ReactElement => {
    const intl = useIntl();

    return (
        <UserManualPage
            title={<FormattedMessage id="e4c.userManual.details.timerChargingMode.title" />}
            dataTestId="timer-charging-mode-item"
        >
            <p>
                <FormattedMessage id="e4c.userManual.details.timerChargingMode.titleDescription" />
            </p>
            <div className="display-flex">
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.timerChargingMode.creation' })}
                    src={detailTimerChargingCreationPanelModalImage}
                    height="550"
                />
                <div className="counter color-onboarding size-small filled margin-left-50">
                    <div>
                        <p className="margin-bottom-0 margin-top-0">
                            <span className="text-bold">
                                <FormattedMessage id="e4c.userManual.details.timerChargingMode.weekDays" />
                            </span>{' '}
                            <FormattedMessage id="e4c.userManual.details.timerChargingMode.weekDays.description" />
                            <span className="counter color-onboarding size-small filled margin-left-10">
                                <span className="display-inline" data-count="2">
                                    <span className="text-bold">
                                        <FormattedMessage id="e4c.userManual.details.timerChargingMode.repeatTimer" />
                                    </span>
                                </span>
                            </span>
                        </p>
                    </div>
                    <CounterItem count={3} id="chargeUntil" />
                    <CounterItem count={4} id="climateControl" />
                    <CounterItem count={5} id="readyToDrive" />
                </div>
            </div>
            <div className="margin-y-25">
                <FormattedMessage id="e4c.userManual.details.timerChargingMode.titleDescription2" />
            </div>
            <div className="display-flex">
                <div className="counter color-onboarding size-small filled margin-right-50">
                    <CounterItem count={1} id="addNewTimer" />
                    <CounterItem count={2} id="editDelete" />
                    <CounterItem count={3} id="activateDeactivate" />
                    <CounterItem count={4} id="nextOccurrences" />
                </div>
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.timerChargingMode.edition' })}
                    src={detailTimerChargingEditionPanelModalImage}
                    height="321"
                />
            </div>
        </UserManualPage>
    );
};

export default TimerChargingModeItem;
